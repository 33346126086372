import { FC } from 'react';
import { HeadBlock } from './common/HeadBlock';
import { Skills } from './common/Skills';
import '../scss/reset.module.scss';
import '../scss/base.module.scss';
import classess from '../scss/profile.module.scss';

export const Profile: FC = () => {
    return (
        <>
        <HeadBlock title="Profile" description="About me | Profile" path="/profile" />
            <h1 className={classess.ch1 + ' ' + classess.p_ch1}><span>Profile</span></h1>
            <section className={classess.prof}>
                <div>
                    <h2>About me</h2>
                    <dl>
 	                    <dt><img src={`${process.env.PUBLIC_URL}/images/prof.gif`} alt="Baru(Border Collie)" /></dt>
 	                    <dd>My name is Baru (Internet name).<br />
                            I am a Web developer and a Designer from Japan.<br />
                            I have experience as a Web developer and a Designer.<br />
                            I graduated with a major in Management and Information Science at university and concurrently attended a vocational school to study Web Design.<br />
                            Therefore, my skills include programming, design, and illustration. I'm also interested in Cyber Security.<br />
                            I used to use Dreamweaver, Photoshop, Illustrator, and Fireworks at work, but now I am using Visual Studio Code and Affinity.<br />
                            Currently, I am improving my skills daily and have been focusing on learning React, TypeScript, Go (Golang), and Cyber Security recently through self-study using books and online resources.</dd>
                    </dl>
                    <Skills />
                </div>
            </section>
            <section className={classess.education + ' ' + classess.profbox}>
                <div>
                    <h3>Education</h3>
                    <p>I started creating websites when I was in junior high school. Since then, web design and illustration have been my hobbies.</p>
                    <p>At first, I wanted to major in Film and Web Design. However, my parents told me to take up Programming and Networking instead. As a result, I graduated with a major in Management and Information Science. At the same time, I took a 6-month vocational course in Web Design.</p>
                </div>
            </section>
            <section className={classess.work + ' ' + classess.profbox}>
                <div>
                    <h3>Work experience</h3>
                    <p>In 2009, I started my first job as a Front End Developer for a private IT company in Nagoya, Japan where I worked for four and a half years. In 2012, I won the best coder award in my company.</p>
                    <p>I did a 2 months internship working as a UI Designer and a Front End Developer for a local company in Malaysia. I was able to work in an English speaking environment. Unfortunately, halfway though my internship, I was robbed on my way to the office. After that incident I was transferred to a remote location, where I worked from home.</p>
                </div>
            </section>
            <section className={classess.philippines + ' ' + classess.profbox}>
                <div>
                    <h3>Studying English and Chinese</h3>
                    <p>In September 2013, I went to the Philippines to study English in Baguio City for 3 months. For the first two months, I learned basic English conversation, and in my final term, I had lessons in basic English and business English.</p>
                    <p>In August 2024, I took an online intermediate-level Chinese course. For the final test, I gave a presentation in Chinese using Keynote.</p>
                </div>
            </section>
            <section className={classess.taiwan + ' ' + classess.profbox}>
                <div>
                    <h3>My visit to Taiwan</h3>
                    <p>In March 2014, I went to Taiwan for a month of sightseeing. I stayed in a shared house in Taipei, and the host was Taiwanese. I had one week to tour Tainan and Kaohsiung. Staying in the shared house in Taipei was a great experience because I met many different people from various countries. Since I was living with people of various nationalities, I was able to speak English every day. I also went to many Chinese museums in Taiwan. As a result, I was able to experience a lot of Chinese culture.</p>
                </div>
            </section>
            <section className={classess.learn + ' ' + classess.profbox}>
                <div>
                    <h3>About illness recovery and self-study</h3>
                    <p>After Malaysia, I developed carpal tunnel syndrome. Therefore, I had surgery. However, I experienced the aftereffects of the operation. So I couldn't work for a long time because I couldn't use my arm. But I have finally recovered by rehabilitation recently. I'm so happy!</p>
                    <p>In April 2020, I had planned to go to study abroad to become an AI Engineer again, but I had to cancel it because of the COVID-19. I was studying Python and English in preparation for studying abroad.</p>
                    <p>In November and December 2021, I successfully completed the cyber security basic and advanced course at an online seminar from R-PAC.</p>
                    <p>I like learning new programming languages. I am currently studying React, TypeScript, and Go (Golang) on my own using books and online resources. And I study English and Chinese as a hobby using Duolingo everyday.</p>
                </div>
            </section>
        </>
    );
};