import { FC } from 'react';
import { HeadBlock } from './common/HeadBlock';
import { Skills } from './common/Skills';
import '../scss/reset.module.scss';
import '../scss/base.module.scss';
import classess from '../scss/jp.module.scss';

export const Japan: FC = () => {
    return (
        <>
        <HeadBlock title="私について" description="私について | 日本語" path="/jp" />
        <h1 className={classess.ch1 + ' ' + classess.jp_ch1}><span>私について</span></h1>
        <section className={classess.jp_about}>
            <div>
                <h2>Baru</h2>
                <p>中学生の頃から趣味でWeb制作を始め、大学ではネットワークやプログラミングを学び、ダブルスクールで専門学校に通い、Web制作の基礎を身につけました。<br />
                卒業後はWeb制作会社に入社し、主にECサイトのコーディングやCMS構築、PHPプログラミングなどディレクション以外の業務に携わりました。退職後はフィリピンに3ヶ月間語学留学し、その後、台湾に1か月滞在後、インターンシップでマレーシアの現地のローカル企業に就業しUI/UXデザイナー兼フロントエンドエンジニアとしてデザイン、コーディングに携わりました。</p>
                <p>このWebサイトは、元々HTML5/CSS3、jQuery、WordPressで制作していたものを独学で学習したReeact、TypeScript、SCSSで制作し直しました。他にもPythonやGoなどの言語も独学で本やオンライン教材を使って学習をしています。また、オンラインセミナーにも参加し、サイバーセキュリティについて学びました。</p>
                <Skills />
            </div>
        </section>
        <section className={classess.jp_box}>
            <div>
                <h3>新しいことを学ぶのが好きです</h3>
                <p>初めてホームページを作成したのが、中学生の頃で、ホームページビルダーでした。Photoshopで絵を描くようになったのも中学生からです。高校では秀丸エディターを使いながら当時はタグ打ちでホームページを作成していました。</p>
                <p>2020 年 4 月に再びIT留学（AIコース）をしようと計画し、学校からの課題（英語、プログラミング、数学）を勉強しながら準備をしていたのですが、新型コロナウイルスにより留学を断念しました。留学こそコロナで出来ませんでしたが、その後、Pythonを独学で勉強しました。</p>
                <p>再び腕の調子が悪くなってしまったので、どうしようか考えていましたが、2021年11月と12月、サイバーセキュリティ研修を受講し、基礎講座と応用講座を修了しました。それをきっかけに再度整形外科に通い、今度は時間が掛かったのですが、原因がわかり、それをきっかけにReeact、TypeScript、SCSS、Go、SQLの勉強を始めました。現在も英語と中国語と一緒にプログラミングは独学で学習をしています。</p>
            </div>
        </section>
        <section className={classess.jp_box}>
            <div>
                <h3>海外経験と語学</h3>
                <p>2013年、英語ゼロからフィリピンに3か月留学しました。マンツーマンでスピーキングを2か月、ビジネス英語を1か月学びました。元々英語が苦手ででしたが、幼稚園の時に英語をやっていたせいかリスニングは得意だった為、3か月である程度日常会話のスピーキングが出来るようになりました。</p>
                <p>その後、台湾で1か月滞在し、英語を使ってシェアハウスを借りて、日本人一人の状況で1か月台湾で暮らしました。英語ができるようになったおかげで台湾人をはじめ多国籍の友人ができました。また、マレーシアでは日本人が私だけの現地ローカル企業でインターンシップにも参加しました。</p>
                <p>2024年には今まで独学で勉強していた中国語をもっと伸ばそうと考え、ハルビン師範大学の3週間のオンライン留学に参加しました。最終試験では中国語でプレゼンテーションを行うことも出来ました。</p>
                <p>現在もアプリ等を使って英語と中国を学習しています。</p>
            </div>
        </section>
        <section className={classess.jp_box}>
            <div>
                <h3>病気との戦い</h3>
                <p>海外から帰国後、手根管症候群と診断されました。</p>
                <p>その後、1年受験勉強をして看護学校入学したものの実習の大量の手書きレポート作業により字が書けなくなるくらい握力が低下し、ドクターストップとなり退学しました。<br />
                退学後、まだ手の筋肉があったため医師の指示で極力手を使わないで1年療養をしていたのですが、症状が改善せず精密検査を受けたところ、神経に障害が発覚して手術を受けました。</p>
                <p>また手術後、今度は右腕に圧迫感をずっと感じるようになり、ずっと原因不明で色んな整形外科に通院し、やっと別の原因が発覚しました。（脇と胸の間の筋肉が固くなっていた）筋肉を柔らかくする注射やリハビリを数ヶ月経て、医師から完治と言われ、現在は通院もしていません。</p>
                <p>今は長時間のPC作業も問題なくできます。理学療法士さんから自宅で出来る腕のストレッチの方法も教えてもらったため今後も問題ないです。</p>
            </div>
        </section>
        </>
    );
};